import {
  Grid,
  IconButton,
  InputAdornment,
  Button,
  Typography,
  CircularProgress,
  Box,
  Link,
  Stack,
} from '@mui/material'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import React, { useState } from 'react'
import { Container } from './styles'
import * as yup from 'yup'
import { TWO_WORDS_REQUIRED } from '../../helpers/regexes'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useUser } from '../../hooks/useUser'
import { useAlert } from '../../hooks/useAlert'
import TextFormField from '../../components/TextFormField'
import { ReturnButton } from '../../components/ReturnButton'
import { MaskedInput } from '../../components/MaskedInput'

const validationSchema = yup.object({
  fullname: yup
    .string()
    .required('Nome completo é um campo obrigatório')
    .matches(TWO_WORDS_REQUIRED, 'Escreva nome e sobrenome'),
  cpf: yup.string(),
  telephone: yup.string().required('Telefone é um campo obrigatório'),
  email: yup.string().email('Email inválido').required('Email é um campo obrigatório'),
  password: yup.string().required('Senha é um campo obrigatório'),
  confirmPassword: yup
    .string()
    .required('Você precisa confirmar sua senha')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref('password')], 'Senhas precisam ser iguais'),
    }),
})

const validationSchemaFirstStep = yup.object({
  fullname: yup
    .string()
    .required('Nome completo é um campo obrigatório')
    .matches(TWO_WORDS_REQUIRED, 'Escreva nome e sobrenome'),
  cpf: yup.string(),
  telephone: yup.string().required('Telefone é um campo obrigatório'),
  email: yup.string().email('Email inválido').required('Email é um campo obrigatório'),
})

interface RegisterContainerProps {
  fullWidth?: boolean
  onFinish: () => void
  onReturn: () => void
  email?: string
  color?: 'primary' | 'secondary'
}

export const RegisterForm: React.FC<RegisterContainerProps> = ({
  onFinish,
  fullWidth = false,
  onReturn,
  email,
  color = 'primary',
}) => {
  const [step, setStep] = useState(0)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)

  const { RegisterUser } = useUser()
  const { showMessage } = useAlert()

  const initialValues = {
    fullname: '',
    cpf: '',
    telephone: '',
    email: email || '',
    password: '',
    confirmPassword: '',
  }

  const submit = async (values: typeof initialValues, helpers: FormikHelpers<any>) => {
    if (step === 0) {
      setStep(1)
      return await helpers.validateForm()
    }
    const res = await RegisterUser({
      firstname: values.fullname.split(' ')[0],
      lastname: values.fullname.split(' ').slice(1).join(' '),
      cpf: values.cpf,
      password: values.password,
      email: values.email,
      telephone: values.telephone,
    })

    if (!res.ok) {
      if (res.status === 400) {
        showMessage('usuario já cadastrado', 'error')
        setStep(0)
        helpers.setFieldError('email', 'email já cadastrado')
      } else showMessage((res.data as any).error, 'error')
      return
    }

    onFinish()
  }

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        validationSchema={step === 0 ? validationSchemaFirstStep : validationSchema}
        onSubmit={submit}
        enableReinitialize
      >
        {({ isValid, isSubmitting, dirty }) => {
          return (
            <Form>
              <Stack
                position="relative"
                alignItems="center"
                direction="row"
                justifyContent={'center'}
                mb={4}
              >
                <Box position="absolute" left="0">
                  <ReturnButton
                    onClick={() => {
                      if (step === 0) onReturn()
                      if (step > 0) setStep((value) => value - 1)
                    }}
                  />
                </Box>
                <Typography fontSize={20}>Entrar com e-mail</Typography>
              </Stack>
              <Grid mb={3} container spacing={2}>
                {step === 0 && (
                  <React.Fragment>
                    <Grid item xs={12} sm={12} md={12} lg={fullWidth ? 12 : 6}>
                      <Typography>Nome completo</Typography>
                      <Field component={TextFormField} fullWidth name="fullname" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={fullWidth ? 12 : 6}>
                      <Typography>Número Celular</Typography>
                      <Field component={TextFormField} fullWidth name="telephone" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={fullWidth ? 12 : 6}>
                      <Typography> CPF (Opcional)</Typography>
                      <Field as={MaskedInput} mask="999.999.999-99" fullWidth name="cpf" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={fullWidth ? 12 : 6}>
                      <Typography>E-mail</Typography>
                      <Field component={TextFormField} fullWidth name="email" />
                    </Grid>
                  </React.Fragment>
                )}
                {step === 1 && (
                  <React.Fragment>
                    <Grid item xs={12} sm={12} md={12} lg={fullWidth ? 12 : 6}>
                      <Typography>Crie uma senha</Typography>
                      <Field
                        component={TextFormField}
                        fullWidth
                        name="password"
                        type={passwordVisible ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Exibir senha"
                                onClick={() => setPasswordVisible(!passwordVisible)}
                              >
                                {passwordVisible ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={fullWidth ? 12 : 6}>
                      <Typography>Confirme a senha</Typography>
                      <Field
                        component={TextFormField}
                        fullWidth
                        name="confirmPassword"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="Exibir senha"
                                onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                              >
                                {confirmPasswordVisible ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        type={confirmPasswordVisible ? 'text' : 'password'}
                      />
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              <Button
                fullWidth
                variant="contained"
                color={color}
                size="large"
                type="submit"
                disabled={!isValid || isSubmitting || !dirty}
              >
                {isSubmitting ? <CircularProgress size={24} /> : 'Continuar'}
              </Button>
              <Typography textAlign="center" mt={3}>
                Já tem conta?
                <Link ml={1} style={{ cursor: 'pointer' }} color={color} onClick={onReturn}>
                  Entrar
                </Link>
              </Typography>
            </Form>
          )
        }}
      </Formik>
    </Container>
  )
}
