import React, { useContext } from 'react'
import { ThemeProvider as LocalThemeProvider } from '../../components/ThemeProvider'
import { ThemeProvider } from '@mypharma/react-components'
import { TabBarProvider } from '../../contexts/tabbar.context'
import { Container, InnerContainer } from './styles'
import AuthContext from '../../contexts/auth.context'
import { WhatsappButton } from '../../components/WhatsappButton'
import { CartDrawer } from '../../components/CartDrawer'
import { ThemeProvider as MuiThemeProvider } from '@mui/material'
import { CreateMuiTheme } from '../../helpers/createMuiTheme'
import { AlertProvider } from '../../contexts/alert.context'
import { makePalette } from '../../helpers/makePalette'
import { MenuDrawer } from '../../components/MenuDrawer'
import { CookieProvider } from '../../contexts/cookies.context'
import { Header } from '../../components/Header'
import { Footer } from '../../components/Footer'
import { useLocation } from 'react-router'
import { LayoutContext, LayoutProvider } from '../../contexts/layout.context'
import { DownloadAppPopup } from '../../components/DownloadAppPopup'

export const Layout: React.FC = ({ children }) => {
  const { store } = useContext(AuthContext)
  const { pathname } = useLocation()

  const theme = makePalette({
    primary: store?.settings.config_navbar_color || '#0B6AF2',
    secondary: store?.settings.config_secondary_color || '#37b910',
    cta: store?.settings.config_navbar_text_color || '#fffff',
  })

  return (
    <ThemeProvider theme={theme}>
      <LocalThemeProvider theme={theme}>
        <MuiThemeProvider theme={CreateMuiTheme(theme)}>
          <AlertProvider>
            <CookieProvider>
              <LayoutProvider>
                <LayoutContext.Consumer>
                  {({ isHeaderHidden, isFooterHidden, isContainerHidden }) =>
                    pathname !== '/checkout' ? (
                      <TabBarProvider>
                        {!isHeaderHidden && <Header />}
                        <MenuDrawer />
                        <CartDrawer />
                        {isContainerHidden ? (
                          children
                        ) : (
                          <Container
                            isHeaderHidden={isHeaderHidden}
                            isFooterHidden={isFooterHidden}
                          >
                            <InnerContainer>{children}</InnerContainer>
                          </Container>
                        )}
                        {!isContainerHidden &&
                          store?.settings.config_whatsapp_button &&
                          !pathname.includes('/produtos/') && <WhatsappButton />}
                        {!isFooterHidden && <Footer />}
                        <DownloadAppPopup />
                      </TabBarProvider>
                    ) : (
                      children
                    )
                  }
                </LayoutContext.Consumer>
              </LayoutProvider>
            </CookieProvider>
          </AlertProvider>
        </MuiThemeProvider>
      </LocalThemeProvider>
    </ThemeProvider>
  )
}
