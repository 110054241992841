import React, { createContext, useCallback, useEffect, useState } from 'react'
import { StorageKeys } from '../config/keys'
import { useAuth } from '../hooks/useAuth'
import Cart from '../interfaces/cart'
import { IPurchased } from '../interfaces/remarketing'
import { loadCartByFingerprint, saveCart, remarketginCart } from '../services/cart/cart.service'
import { loadStorage, saveStorage } from '../services/storage/storage.service'

interface NotifyCartData {
  open: boolean
  text: string
}

interface CartContextData {
  open: boolean
  cart: Cart
  notify: NotifyCartData
  loadCart: () => Promise<void>
  setOpen: (open: boolean) => void
  getProceedCheckout: () => boolean
  setProceedCheckout: (active: boolean) => void
  setCart: React.Dispatch<React.SetStateAction<Cart>>
  clearRemarketingCart: () => Promise<void>
  setRemarketingCart: (purchased: IPurchased) => Promise<void>
  setNotify: React.Dispatch<React.SetStateAction<NotifyCartData>>
}

const CartContext = createContext({} as CartContextData)
const { Provider } = CartContext

export const CartProvider: React.FC = ({ children }) => {
  const { user } = useAuth()
  const [open, setOpen] = useState(false)
  const [notify, setNotify] = useState<NotifyCartData>({ open: false, text: '' })
  const [cart, setCart] = useState<Cart>({ cupom: [], products: [], fingerprint: null })

  const loadCart = useCallback(async () => {
    const storedData = loadStorage(StorageKeys.cart) || {}

    const value = await loadCartByFingerprint(storedData.fingerprint)
    if (value && value.cart) {
      saveStorage(StorageKeys.cart, { fingerprint: value.cart.fingerprint })
      setCart(value.cart)
    }
  }, [])

  const setRemarketingCart = useCallback(async (purchased: IPurchased = "NO") => {
    if (user) {

      const products = cart.products.map(_product => {
        return {
          id: _product.product._id,
          name: _product.product.name,
          slug: _product.product.slug.pop() || '',
          quantity: _product.quantity,
          maxQuantity: _product.product.quantity
        }
      })
      await remarketginCart({ products, purchased })
    }

  }, [user, cart])

  const clearRemarketingCart = useCallback(async () => {
    await remarketginCart({ products: [], purchased: 'YES' })
  }, [])

  useEffect(() => {
    if (notify.open === true) {
      setTimeout((value) => {
        setNotify({ ...value, open: false })
      }, 2500)
    }
  }, [notify])

  useEffect(() => {
    loadCart()
  }, [loadCart])

  useEffect(() => {
    saveCart({
      ...cart,
      cupom: cart!.cupom!.length > 0 ? ([cart!.cupom![0].code] as any) : [],
    })
  }, [cart])

  useEffect(() => {
    setRemarketingCart()
  }, [setRemarketingCart])

  const setProceedCheckout = (active: boolean) => {
    const value = active ? 'true' : 'false'

    localStorage.setItem('proceedCheckout', value)
  }

  const getProceedCheckout = () => {
    const value = localStorage.getItem('proceedCheckout')

    if (value === 'true') {
      return true
    }

    return false
  }

  return (
    <Provider
      value={{
        open,
        cart,
        notify,
        setOpen,
        setCart,
        loadCart,
        setNotify,
        setProceedCheckout,
        getProceedCheckout,
        setRemarketingCart,
        clearRemarketingCart
      }}
    >
      {children}
    </Provider>
  )
}

export default CartContext
