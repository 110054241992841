import React, { useCallback, useContext, useEffect } from 'react'
import AuthContext from '../../contexts/auth.context'
import cookieContext from '../../contexts/cookies.context'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router'
import TagManager from 'react-gtm-module'
import ReactPixel from 'react-facebook-pixel'
import { hotjar } from 'react-hotjar'

export const Analytics: React.FC = () => {
  const { store } = useContext(AuthContext)
  const { isAllowed, initialized, setInitialized } = useContext(cookieContext)
  const { pathname, search } = useLocation()

  const init = useCallback(() => {
    const { config_analytics_id, config_google_tag_manager_id, config_pixel_id, config_hotjar_id } =
      store!.settings

    if (config_analytics_id) {
      ReactGA.initialize(config_analytics_id!)
      ReactGA.ga('require', 'ec')
    }

    if (config_google_tag_manager_id) TagManager.initialize({ gtmId: config_google_tag_manager_id })
    if (config_pixel_id) ReactPixel.init(config_pixel_id)
    if (config_hotjar_id) hotjar.initialize(config_hotjar_id as any, 6)

    setInitialized(true)
  }, [store, setInitialized])

  useEffect(() => {
    if (isAllowed === true && !initialized) {
      init()
    }
  }, [isAllowed, store, initialized, init])

  useEffect(() => {
    const { config_analytics_id, config_pixel_id } = store!.settings

    if (isAllowed && initialized) {
      if (config_analytics_id) ReactGA.pageview(pathname + search)
      if (config_pixel_id) ReactPixel.pageView()
    }
  }, [isAllowed, store, pathname, search, initialized])

  return <React.Fragment />
}
