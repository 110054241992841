import DeliveryFee from '../interfaces/deliveryFee'
import { DeliveryRegions, GetDeliveryRegions } from '../services/delivery/response.interface'

export function getExpensiveRegion({ regions }: GetDeliveryRegions) {
  const deliveryFees: DeliveryFee[] = []
  regions.map((value) => value.deliveryFees.map((deliveryFee) => deliveryFees.push(deliveryFee)))

  return deliveryFees.filter(fee => fee.freeFrom > 0).sort((a, b) => a.freeFrom - b.freeFrom).shift()
}

export function getCheapestRegion({ regions }: GetDeliveryRegions) {
  const deliveryFees: DeliveryFee[] = []
  regions.map((value) => value.deliveryFees.map((deliveryFee) => deliveryFees.push(deliveryFee)))

  deliveryFees.sort((a, b) => (a.freeFrom > b.freeFrom ? 1 : -1))
  return deliveryFees.shift()
}

export function getCheapeastNeighborhood(region: DeliveryRegions) {
  return region.deliveryFees
    .map((value) => value)
    .sort((a, b) => a.freeFrom - b.freeFrom)
    .shift()
}

export function getExpensiveMinimumPurchaseNeighborhood(region: DeliveryRegions) {
  return region.deliveryFees
    .map((value) => value)
    .sort((a, b) => (a.minimumPurchase < b.minimumPurchase ? 1 : -1))
    .shift()
}
