import styled from 'styled-components'

export const PopupContainer = styled.div`
  position: fixed;
  bottom: 80px;
  margin-left: 16px;
  margin-right: 16px;
  padding: 8px;
  border-radius: 8px;
  color : white;
  background: rgba(0,0,0,1);
  z-index: 99999;
  font-size : 12px;
`