import React, { useContext } from 'react'
import { WhatsappIcon } from '../../assets/icons'
import AuthContext from '../../contexts/auth.context'
import { Link } from './styles'

export const WhatsappButton: React.FC = () => {
  const { store } = useContext(AuthContext)

  return (
    <Link
      color={'#51CF66'}
      rel="noopener noreferrer"
      target="_blank"
      href={`https://api.whatsapp.com/send?phone=55${store?.settings.config_whatsapp_phone?.replace(
        /[^0-9]/g,
        ''
      )}&text=Olá, vim pelo site!`}
    >
      <WhatsappIcon height="100%" width="100%" />
    </Link>
  )
}
