import React, { createContext, useEffect, useState } from 'react'
import User from '../interfaces/user'
import useSwr from 'swr'
import { startup } from '../services/store/store.service'
import Store from '../interfaces/store'
import { setAccessToken } from '../config/api'
import { getUserToken } from '../helpers/loggedUser'
import { useAuth } from '../hooks/useAuth'

interface AuthContextData {
  user?: User
  store?: Store
  setUser: (user?: User) => void
  isStarted: boolean
}

const AuthContext = createContext({} as AuthContextData)
const { Provider } = AuthContext

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | undefined>()
  const [isStarted, setisStarted] = useState<boolean>(false)
  const { renewUserToken } = useAuth()
  const { data, error } = useSwr('startup', startup, { revalidateOnFocus: false })

  useEffect(() => {
    if (data && !error) {
      const token = getUserToken()
      setAccessToken(data.accessToken)
      setisStarted(true)
      if (token) {
        renewUserToken().then((value) => {
          if (value?.accessToken && value.user) {
            setUser(value?.user)
            setAccessToken(value!.accessToken)
          } else {
            localStorage.removeItem('@myp/auth')
            setUser(undefined)
          }
        })
      }
    }
  }, [data, error, renewUserToken])

  return (
    <Provider
      value={{
        user,
        setUser,
        store: data?.store,
        isStarted,
      }}
    >
      {children}
    </Provider>
  )
}

export default AuthContext
