import { useContext } from 'react'
import useSWR from 'swr'
import AuthContext from '../contexts/auth.context'
import CheckoutContext from '../contexts/checkout.context'
import { waypointContext } from '../contexts/waypoint.context'
import { getExpensiveRegion } from '../helpers/getExpensiveRegion'
import Address from '../interfaces/address'
import { ISender } from '../interfaces/sender'
import { getAddresses } from '../services/address/address.service'
import { getDeliveryRegions, getRegions } from '../services/delivery/delivery.service'

export const useDelivery = () => {
  const { store, user } = useContext(AuthContext)
  const { checkoutAddress } = useContext(CheckoutContext)
  const { shouldRenderOptionals } = useContext(waypointContext)

  const { data: addressData } = useSWR(user ? `addresses/${user._id}` : null, getAddresses)
  const { data: cities } = useSWR(shouldRenderOptionals ? 'deliveryRegions' : null, getDeliveryRegions)
  const { data: regionsData } = useFetchDelivery(shouldRenderOptionals)

  const companySender: ISender = store?.settings['config_shipping_courier'] ? 'courier' : store?.settings['config_best_shipping'] ? 'bestshipping' : null

  const hasShippingAvailable =
    store?.settings.config_shipping_courier || store?.settings.config_best_shipping

  function getMainAddress(address: Address | Address[] | null) {
    if (address instanceof Array) {
      return address.find(_adress => _adress.isMain)
    }
    return address
  }


  function useFetchDelivery(shouldFetch: boolean) {
    const address = getMainAddress(checkoutAddress || addressData?.addresses || null)

    const { data = [], error } = useSWR(shouldFetch ? 'regions' : null, async () => {
      const response = await getRegions(address?.neighborhood._id || '')

      return response?.regions
    })

    return { data, error }
  }

  const hasLocalDelivery = (address: Address) => {
    return regionsData.find(
      (region) => region.neighborhood._id === address.neighborhood._id
    )
  }


  return {
    companySender,
    hasLocalDelivery,
    useFetchDelivery,
    hasShippingAvailable,
    neighborhoods: regionsData,
    citys: cities?.regions || [],
    getExpensiveRegion: () => getExpensiveRegion({ regions: cities?.regions || [] }),
  }
}
