import { Link, Typography } from '@mui/material'
import React, { useContext } from 'react'
import cookieContext from '../../contexts/cookies.context'
import { AcceptButton, CookieContainer } from './styles'

export const CookiesPopup: React.FC = () => {
  const { allowCookies } = useContext(cookieContext)
  return (
    <CookieContainer>
      <Typography color="white" lineHeight={{ xs: 2, md: 3 }} textAlign="center" fontSize={16}>
        Este site está em conformidade com a Lei Geral de Proteção de Dados (LGPD) e utiliza cookies
        para oferecer uma melhor experiência ao visitante. Ao navegar em nosso site, você concorda
        com a utilização de cookies e com a nossa
        <Link
          href="https://myp-public.s3-us-west-2.amazonaws.com/static/policy.pdf"
          target="_blank"
          rel="noopener noreferrer"
          ml={2}
          color="#fff"
        >
          Política de Privacidade.
        </Link>
      </Typography>
      <AcceptButton onClick={allowCookies}>Concordo</AcceptButton>
    </CookieContainer>
  )
}
