import React, { useMemo, useState } from 'react'
import { isThisDeviceRunningiOS } from '../../helpers/isDeviceIOS'
import { isUsingInstalled } from '../../helpers/isUsingInstalled'
import { PopupContainer } from './styles'
import IosShareIcon from '@mui/icons-material/IosShare'
import { IconButton, Stack } from '@mui/material'
import { CloseIcon } from '../../assets/icons'

export const DownloadAppPopup: React.FC = () => {
  const isIOS = isThisDeviceRunningiOS()
  const isInstalled = isUsingInstalled()

  const [isOpen, setIsOpen] = useState(true)

  const userAgent = useMemo(()=> navigator.userAgent,[])

  return (
    <React.Fragment>
      {isOpen && isIOS && !isInstalled && userAgent.match(/safari/i) && (
        <PopupContainer>
          <Stack direction="row" spacing={1}>
            <span>
              instale este aplicativo no seu iphone: Clique em{' '}
              <IosShareIcon sx={{ marginBottom: '-2px' }} fontSize={'small'} /> e depois adicione à
              tela de inicio
            </span>
            <IconButton onClick={() => setIsOpen(false)} color="inherit">
              <CloseIcon height={20} width={20} />
            </IconButton>
          </Stack>
        </PopupContainer>
      )}
    </React.Fragment>
  )
}
