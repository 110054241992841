import { authApi } from '../../config/api'
import { handleErrorResponse } from '../../helpers/handleErrorResponse'
import { StartupResponse } from './response.interface'

export async function startup() {
  return authApi
    .get<StartupResponse>('/v1/store/startup')
    .then((res) => res.data)
    .catch(handleErrorResponse)
}
