import React, { useContext } from 'react'
import Product from '../../interfaces/product'
import ProductImageExample from '../../assets/ilustration/ProductImageExample.svg'
import { AddButton } from '@mypharma/react-components'
import { getProductActivePromotion } from '../../helpers/getProductActivePromotion'
import { CDN } from '../../config/keys'
import { ProductCard, ProductImage, ProductName } from './styles'
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { TrashIcon } from '../../assets/icons'
import { useCart } from '../../hooks/useCart'
import { getProductPrice } from '../../helpers/getProductPrice'
import { useTheme } from 'styled-components'
import CartContext from '../../contexts/cart.context'
import { useLocation } from 'react-router'
import { floatToBRL } from '../../helpers/moneyFormat'
import { useAuth } from '../../hooks/useAuth'

interface CartProductProps {
  product: Product
  contrast?: boolean
  removeDecreaseButton?: boolean
  removeIncreaseButton?: boolean
  removeQuantityButtons?: boolean
}

export const CartProduct: React.FC<CartProductProps> = ({
  product,
  contrast = false,
  removeDecreaseButton,
  removeIncreaseButton,
  removeQuantityButtons,
}) => {
  const { removeProduct, getProduct, addProduct, getCupomDiscount } = useCart()
  const { cart } = useContext(CartContext)
  const { pathname } = useLocation()
  const { store } = useAuth()
  const { color } = useTheme()

  const promotional = getProductActivePromotion(product)?.price
  const cupomDiscount = getCupomDiscount(product)
  const shouldHideStock = store?.settings.config_stock_display === true

  const cartProduct = getProduct(product._id)!

  return (
    <ProductCard color={contrast ? 'secondary' : 'neutral'} key={product._id}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row">
          <ProductImage
            color={contrast ? 'secondary' : 'neutral'}
            src={product.image ? `${CDN.image}${product.image.key}` : ProductImageExample}
            onError={(e) => {
              e.currentTarget.src = ProductImageExample
              e.currentTarget.style.padding = '0px'
            }}
          />
          <ProductName>{product.name}</ProductName>
        </Stack>
        {pathname === '/checkout' && cart.products?.length === 1 ? (
          <React.Fragment />
        ) : (
          <Tooltip title="Remover produto da cesta">
            <IconButton onClick={() => removeProduct(product._id)}>
              <TrashIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      <Box mt={0} ml={7}>
        <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
          <Stack direction="column">
            <Box width="100%" mb={1}>
              <Typography
                fontWeight={500}
                color={
                  product.quantity > 0 ? color.feedback.approve.medium : color.feedback.error.medium
                }
              >
                {shouldHideStock
                  ? product.quantity > 0
                    ? 'disponível'
                    : 'indisponível'
                  : ` ${product.quantity} disponíveis`}
              </Typography>
            </Box>
            {!removeQuantityButtons ? (
              <AddButton
                onClick={(value, event) => {
                  value === 0
                    ? removeProduct(product._id)
                    : addProduct(
                        product,
                        value,
                        event === 'decrease' ? 'Removido da cesta' : undefined
                      )
                }}
                removeDecrease={removeDecreaseButton || getProduct(product._id)!.quantity === 1}
                disableIncrease={
                  removeIncreaseButton || getProduct(product._id)!.quantity === product.quantity
                }
                value={getProduct(product._id)!.quantity}
              />
            ) : (
              <Typography fontSize={16} fontWeight={700}>
                {cartProduct?.quantity} {cartProduct.quantity > 1 ? 'unidades' : 'unidade'}
              </Typography>
            )}
          </Stack>
          <Box mb={removeQuantityButtons ? 0 : 1}>
            <Stack justifyContent="center" alignItems="center">
              {(promotional || cupomDiscount) && (
                <Typography fontSize={12} style={{ textDecoration: 'line-through' }}>
                  {floatToBRL(product.price)}
                </Typography>
              )}
              <Typography>
                {promotional || cupomDiscount
                  ? floatToBRL(getProductPrice(product.price, promotional, cupomDiscount))
                  : floatToBRL(product.price)}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </ProductCard>
  )
}
