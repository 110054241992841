import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  Button,
  TextField,
} from '@mui/material'
import React, { useState } from 'react'
import { useTheme } from 'styled-components'
import { MailBox } from '../../assets/ilustration'
import { CEP_VALIDATION } from '../../helpers/regexes'
import Store from '../../interfaces/store'
import StoreUrls from '../../interfaces/storeUrls'
import { getStoresByCEP } from '../../services/location/location.service'
import { IReturnStoreThatDelivery } from '../../services/location/response.interface'
import { StoreLinkCard } from './styles'

interface RedirectContentProps {
  urls?: StoreUrls[]
  groups?: Store[]
}

export const RedirectContent: React.FC<RedirectContentProps> = ({ urls, groups }) => {
  const [cep, setCep] = useState<string>('')
  const [fetching, setFetching] = useState<boolean>(false)
  const [storesGrouped, setStoreGrouped] = useState<IReturnStoreThatDelivery[] | undefined>([])
  const { color } = useTheme()

  const handleChange = (event: SelectChangeEvent<string>) => {
    window.location.href = `${event.target.value}?noredirect`
  }

  const handleSubmit = async () => {
    if (CEP_VALIDATION.test(cep)) {
      setFetching(true)
      const response = await getStoresByCEP(cep)
      if (response) {
        setStoreGrouped(response.groups)
      }
      setFetching(false)
    }
  }

  return (
    <Stack spacing={5} justifyContent="center" alignItems="center">
      <MailBox color={color.primary.medium} />
      {urls && urls.length > 0 ? (
        <React.Fragment>
          <Typography>Nos diga onde você está para te direcionarmos à loja mais próxima</Typography>
          <React.Fragment>
            <FormControl fullWidth>
              <InputLabel id="local_label">Selecione uma localidade</InputLabel>
              <Select
                value=""
                onChange={handleChange}
                label="Selecione uma localidade"
                labelId="local_label"
              >
                {urls?.map((url, index) => {
                  return (
                    <MenuItem key={index} value={url.url_address}>
                      {url.url_name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </React.Fragment>
        </React.Fragment>
      ) : groups && groups.length > 0 && storesGrouped && storesGrouped.length > 0 ? (
        <Stack spacing={2} width="100%">
          {storesGrouped.map((store, index) => (
            <StoreLinkCard key={index}>
              <Typography fontWeight={500} color={color.neutral.darkest} fontSize={16}>
                {store.name}
              </Typography>
              <Typography color={color.neutral.darkest} fontSize={16}>
                {store.address.street}, {store.address.number}, {store.address.city}
              </Typography>
              <Button
                sx={{ paddingX: 6 }}
                LinkComponent={Link}
                href={`${store.url}?noredirect`}
                target="_blank"
                variant="contained"
                color="primary"
              >
                Navegar para essa loja
              </Button>
            </StoreLinkCard>
          ))}
        </Stack>
      ) : (
        <Stack gap={1}>
          <Typography mb={1} textAlign={'center'} fontSize={16}>
            Localizaremos as farmácias máis próximas
          </Typography>
          <Typography textAlign={'center'} fontSize={14}>
            Digite seu CEP e nos diga onde você está
          </Typography>
          <TextField
            value={cep}
            onChange={(event) => setCep(event.target.value)}
            placeholder="Ex: 59301-000"
          />
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              disabled={cep.length !== 8}
              onClick={handleSubmit}
              fullWidth
              endIcon={fetching && <CircularProgress color="secondary" size={18} />}
            >
              {fetching ? 'Buscando...' : 'Buscar'}
            </Button>
          </Box>
        </Stack>
      )}
    </Stack>
  )
}
