import { paymentApi, siteApi } from '../../config/api'
import Cart from '../../interfaces/cart'
import { IRemarketingRequest } from './request.interface'
import { LoadCartByFingerprintResponse, RemarketingResponse, SaveCartResponse } from './response.interface'

export function loadCartByFingerprint(fingerprint: Cart['fingerprint']) {
  return siteApi
    .get<LoadCartByFingerprintResponse>(`/v2/cart/${fingerprint}`)
    .then((res) => res.data)
}

export function saveCart(cart: Partial<Cart>) {
  return siteApi.put<SaveCartResponse>('/v2/cart', { cart }).then((res) => res.data)
}

export function remarketginCart(data: IRemarketingRequest) {
  return paymentApi.put<RemarketingResponse>('/v1/cart/send', data)
}
