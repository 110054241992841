export const minutesToTime = (minutes: number) => {
  if (minutes < 60) {
    return {
      value: minutes,
      suffix: 'min',
    }
  }

  if (minutes >= 60 && minutes < 1440) {
    return {
      value: Number((minutes / 60).toFixed(0)),
      suffix: 'hr',
    }
  }

  return {
    value: Number((minutes / 1440).toFixed(0)),
    suffix: 'dias',
  }
}
