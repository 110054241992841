import { isBefore } from 'date-fns'
import { addDays } from 'date-fns/esm'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import AuthContext from '../../contexts/auth.context'
import { CheckoutModal } from '../CheckoutModal'
import { RedirectContent } from '../RedirectContent'

export const RedirectModal: React.FC = () => {
  const { store } = useContext(AuthContext)
  const [storeUrls, setStoreUrls] = useState(false)
  const [storeGroups, setStoreGroups] = useState(false)
  const { search } = useLocation()

  useEffect(() => {
    if (store) {
      const { urls, groups } = store

      const session = localStorage.getItem('e_commerce_session')
        ? new Date(localStorage.getItem('e_commerce_session')!)
        : undefined

      if (!session || (session && isBefore(session, new Date()))) {
        if (
          urls &&
          urls.length > 0 &&
          (!groups || groups.length <= 0) &&
          search !== '?noredirect'
        ) {
          setStoreUrls(true)
          localStorage.setItem('e_commerce_session', addDays(new Date(), 1).toString())
        }

        if (
          (!urls || urls.length <= 0) &&
          groups &&
          groups.length > 0 &&
          search !== '?noredirect'
        ) {
          setStoreGroups(true)
          localStorage.setItem('e_commerce_session', addDays(new Date(), 1).toString())
        }
      }
    }
  }, [store, search])

  const handleClose = () => {
    if (storeUrls) {
      setStoreUrls(false)
    }

    if (storeGroups) {
      setStoreGroups(false)
    }
  }

  return (
    <CheckoutModal onClose={handleClose} open={storeUrls || storeGroups}>
      {store?.urls && store.urls.length > 0 && <RedirectContent urls={store?.urls} />}
      {store?.groups && store.groups.length > 0 && <RedirectContent groups={store?.groups} />}
    </CheckoutModal>
  )
}
