const { REACT_APP_IMAGES_CDN } = process.env

export const StorageKeys = {
  cart: '@mypharma/cart',
  user: '@mypharma/user',
  store: '@mypharma/store',
  search: 'search_history'
}

export const CDN = {
  image: REACT_APP_IMAGES_CDN,
}
