import { useCallback, useContext } from 'react'
import AuthContext from '../contexts/auth.context'
import cookieContext from '../contexts/cookies.context'
import Product from '../interfaces/product'
import ReactGA from 'react-ga'
import { getProductActivePromotion } from '../helpers/getProductActivePromotion'
import Order from '../interfaces/order'

export const useEC = () => {
  const { store } = useContext(AuthContext)
  const { initialized } = useContext(cookieContext)

  const buildGAproduct = useCallback((product: Product, list?: string, position?: number) => {
    const { EAN, name, manufacturer, category, price } = product

    return {
      id: EAN.toString(),
      name,
      brand: manufacturer?.name || 'Não possui',
      list,
      position,
      category: category && category.length > 0 ? category[0].name : 'Não possui',
      price: getProductActivePromotion(product) ? getProductActivePromotion(product)?.price : price,
    }
  }, [])

  const addImpression = useCallback(
    (products: Product[], list: string) => {
      if (!store?.settings.config_analytics_id || !initialized) return

      products.map((product, index) => {
        return ReactGA.ga('ec:addImpression', buildGAproduct(product, list, index))
      })

      ReactGA.ga('send', 'pageview')
    },
    [store, initialized, buildGAproduct]
  )

  const viewProduct = useCallback(
    (product: Product) => {
      if (!store?.settings.config_analytics_id || !initialized) return

      ReactGA.ga('ec:addProduct', buildGAproduct(product))
      ReactGA.ga('ec:setAction', 'detail')
      ReactGA.ga('send', 'pageview')
    },
    [store, initialized, buildGAproduct]
  )

  const setStep = useCallback(
    (step: number, option?: string) => {
      if (!store?.settings.config_analytics_id || !initialized) return

      ReactGA.ga('require', 'ec')
      ReactGA.ga('ec:setAction', 'checkout', {
        step,
        option,
      })
      ReactGA.ga('send', 'pageview')
    },
    [store, initialized]
  )

  const purchase = useCallback(
    (order: Order) => {
      if (!store?.settings.config_analytics_id || !initialized) return

      ReactGA.ga('require', 'ec')

      order.products.map(({ product }, index) => {
        return ReactGA.ga('ec:addProduct', buildGAproduct(product))
      })

      ReactGA.ga('ec:setAction', 'purchase', {
        id: order._id,
        affiliation: store.name,
        revenue: order.orderTotals.find((value) => value.code === 'total').value,
        tax: 0,
        shipping: order.orderTotals.find((value) => value.code === 'shipping').value,
        coupon: 'Sem coupon',
      })

      ReactGA.ga('send', 'pageview')
      setStep(3)
      ReactGA.ga('send', 'pageview')
    },
    [store, initialized, setStep, buildGAproduct]
  )

  const addToCart = useCallback(
    (product: Product, page: string) => {
      if (!store?.settings.config_analytics_id || !initialized) return

      ReactGA.ga('require', 'ec')
      ReactGA.ga('ec:addProduct', buildGAproduct(product))
      ReactGA.ga('ec:setAction', 'add')
      ReactGA.ga('send', 'event', page, 'click', 'addToCart')
    },
    [store, initialized, buildGAproduct]
  )

  return {
    addImpression,
    viewProduct,
    purchase,
    addToCart,
  }
}
