import { useCallback, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import AuthContext from '../contexts/auth.context'
import searchContext from '../contexts/search.context'
import { searchProducts } from '../services/search/search.service'

export const useSearch = () => {
  const { store } = useContext(AuthContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const { setIsValidating, setData, fingerprint, products, setQuery, query, isValidating } = useContext(searchContext)
  const hasQuery = searchParams.has('q')

  const search = useCallback(
    async (text: string | null) => {

      if (text && text.trim().length > 0) {
        if (hasQuery) {
          setSearchParams({ q: text })
        }
        setQuery(text)
        setIsValidating(true)
        const response = await searchProducts({
          store,
          tenant: store!.tenant,
          fingerprint,
          query: text,
        })

        setData(response)
        setIsValidating(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fingerprint, store, searchParams, setData]
  )

  const clearSearch = () => {
    setQuery('')
    setData(undefined)
    setSearchParams({}, { replace: true })
  }

  return {
    search,
    clearSearch,
    query,
    products,
    isValidating,
  }
}
