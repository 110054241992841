import { Button, CircularProgress } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useContext } from 'react'
import * as yup from 'yup'
import { saveAddress } from '../../services/address/address.service'
import Address from '../../interfaces/address'
import { AddressFormFields } from './fields'
import Neighborhood from '../../interfaces/neighborhood'
import { useDelivery } from '../../hooks/useDelivery'
import { ControlledAddressFields } from '../ControlledAddressFields'
import AuthContext from '../../contexts/auth.context'
import { normalizeStr } from '../../helpers/normalizeString'

interface AddressFormProps {
  onFinish?: () => void
  address?: Address
  buttonColor?: 'primary' | 'secondary'
}

const validationSchema = yup.object({
  postcode: yup.string().min(8, 'Cep invalido').max(9, 'Cep invalido').required('CEP obrigatório'),
  number: yup.string(),
  neighborhood: yup.object({
    name: yup.string().required('Bairro obrigatório'),
    city: yup.object({
      name: yup.string().required(),
      state: yup.object({
        name: yup.string().required(),
      }),
    }),
  }),
  street: yup.string().required('Rua obrigatório'),
  complement: yup.string(),
  isMain: yup.bool(),
})

const localValidationSchema = yup.object({
  postcode: yup.string().min(8, 'Cep invalido').max(9, 'Cep invalido'),
  number: yup.string(),
  neighborhood: yup.object({
    name: yup.string().required('Bairro obrigatório'),
    city: yup.object({
      name: yup.string().required(),
      state: yup.object({
        name: yup.string().required(),
      }),
    }),
  }),
  street: yup.string().required('Rua obrigatório'),
  complement: yup.string(),
  isMain: yup.bool(),
})

export const AddressForm: React.FC<AddressFormProps> = ({ onFinish, address, buttonColor }) => {
  const { hasShippingAvailable, citys } = useDelivery()
  const { store } = useContext(AuthContext)

  const defaultCity = citys.find(
    (value) =>
      normalizeStr(value._id).toLowerCase() ===
      normalizeStr(store?.settings.config_store_city?.toLowerCase() || '')
  )

  const initialValues = address || {
    postcode: '',
    number: '',
    street: '',
    complement: '',
    neighborhood: {
      _id: '',
      name: '',
      city: {
        name: defaultCity?._id || '',
        state: {
          name: defaultCity?.deliveryFees[0].neighborhood.city.state.name || '',
        },
      },
    },
    isMain: true,
  }

  const handleSubmit = async (values: any) => {
    const response = await saveAddress({
      id: values._id,
      postcode: values.postcode || '',
      number: values.number || '',
      street: values.street || '',
      complement: values.complement,
      neighborhood: values.neighborhood as Neighborhood,
      isMain: values.isMain || true,
    })

    if (onFinish && response.ok) await onFinish()
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={hasShippingAvailable ? validationSchema : localValidationSchema}
      enableReinitialize
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Form>
          {hasShippingAvailable ? <AddressFormFields /> : <ControlledAddressFields />}
          <Button
            fullWidth
            variant="contained"
            disabled={!isValid || isSubmitting || !dirty}
            type="submit"
            color={buttonColor}
          >
            {isSubmitting ? <CircularProgress size={20} /> : 'Continuar'}
          </Button>
        </Form>
      )}
    </Formik>
  )
}
