import { Box, Divider, Stack, Link, Typography } from '@mui/material'
import React, { useContext } from 'react'
import {
  BoletoIcon,
  LocationIcon,
  MailIcon,
  MasterCard,
  MoneyIcon,
  PhoneIcon,
  VisaIcon,
} from '../../assets/icons'
import AuthContext from '../../contexts/auth.context'
import { formatPhoneNumber } from '../../helpers/formatPhoneNumber'
import { Container, IconWrapper, LegalInformations } from './styles'
import { ReactComponent as SafeBrowsingLogo } from '../../assets/ilustration/SafeBrowsingLogo.svg'
import { ReactComponent as MypharmaLogo } from '../../assets/ilustration/MypharmaLogo.svg'
import { CDN } from '../../config/keys'
import { useTheme } from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { getPaymentMethods } from '../../services/payment/payment.service'
import PicpayIlustration from '../../assets/ilustration/picpay.webp'
import PixIlustration from '../../assets/ilustration/pix.webp'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import useSWR from 'swr'
import { waypointContext } from '../../contexts/waypoint.context'

export const Footer: React.FC = () => {
  const { store } = useContext(AuthContext)
  const { shouldRenderOptionals } = useContext(waypointContext)
  const { color } = useTheme()

  const { data: paymentOptionsRequest } = useSWR('paymentMethods', () =>
    shouldRenderOptionals ? getPaymentMethods() : undefined
  )

  const paymentOptionToIcon: any = {
    pix: <img loading="lazy" src={PixIlustration} key="pix" height={'auto'} width={70} alt="Pix" />,
    picpay: (
      <img
        loading="lazy"
        key={'picpay'}
        src={PicpayIlustration}
        height={'auto'}
        width={70}
        alt="picpay"
      />
    ),
    stone: (
      <Box key="stone" color="white">
        <QrCode2Icon color="inherit" />
      </Box>
    ),
    boleto: <BoletoIcon key="boleto" color="white" />,
  }

  return (
    <Container>
      <Box m={3} mt={0}>
        <Stack
          justifyContent={{ md: 'space-between', lg: 'center' }}
          gap={{ lg: '150px' }}
          direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }}
        >
          <Stack spacing={3}>
            <Typography
              fontWeight="bold"
              mt={4}
              mb={1}
              fontSize={24}
              color={color.neutral.lightest}
              variant="h3"
            >
              Institucional
            </Typography>
            <Link
              color={color.neutral.light}
              fontSize={16}
              underline={'none'}
              to="sobre"
              component={RouterLink}
            >
              Sobre nós
            </Link>
            <Link
              color={color.neutral.light}
              fontSize={16}
              underline={'none'}
              target="_blank"
              rel="noopener noreferrer"
              href="https://myp-public.s3-us-west-2.amazonaws.com/static/policy.pdf"
            >
              Política de privacidade
            </Link>
            <Link
              color={color.neutral.light}
              fontSize={16}
              underline={'none'}
              target="_blank"
              rel="noopener noreferrer"
              href="https://myp-public.s3.us-west-2.amazonaws.com/Pol%C3%ADticas+de+trocas+e+devolu%C3%A7%C3%B5es.pdf"
            >
              Trocas e devoluções
            </Link>
          </Stack>
          <Stack spacing={3}>
            <Typography
              fontWeight="bold"
              mt={4}
              mb={1}
              fontSize={24}
              color={color.neutral.lightest}
              variant="h3"
            >
              {store?.name}
            </Typography>
            <Stack direction="row" spacing={1}>
              <IconWrapper>
                <LocationIcon />
              </IconWrapper>
              <Typography fontSize={16} color={color.neutral.light}>
                <strong>{store?.settings.config_address}, {store?.settings.config_store_number}</strong> <br />
                {store?.settings.config_store_city}. <br />
                CEP: {store?.settings.config_cep}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <IconWrapper>
                <PhoneIcon />
              </IconWrapper>
              <Typography fontSize={16} color={color.neutral.light}>
                {formatPhoneNumber(store?.settings.config_phone || '')}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <IconWrapper>
                <MailIcon />
              </IconWrapper>
              <Typography
                style={{ wordBreak: 'break-word' }}
                fontSize={16}
                color={color.neutral.light}
              >
                {store?.settings.config_email}
              </Typography>
            </Stack>
          </Stack>

          <Stack>
            <Box mt={3}>
              {shouldRenderOptionals && (
                <img
                  loading="lazy"
                  style={{ maxHeight: 250, borderRadius: '100%' }}
                  src={new URL(store?.settings.config_logo || '', CDN.image).href}
                  alt={store?.name}
                />
              )}
            </Box>
            <Typography
              variant="h4"
              fontSize={24}
              mt={4}
              mb={4}
              fontWeight="bold"
              color={color.neutral.lightest}
            >
              Querido cliente!
            </Typography>
            <Typography fontSize={20} variant="h4" color={color.neutral.lightest}>
              É um prazer ter você aqui
            </Typography>
          </Stack>
        </Stack>
      </Box>

      <Box mt={6} mb={6}>
        <Divider />
      </Box>

      <Stack
        direction={{ xs: 'column', md: 'row' }}
        p={5}
        justifyContent="center"
        spacing={5}
        pb={5}
      >
        <Box>
          <Stack alignItems={{ md: 'center' }} justifyContent={{ md: 'center' }}>
            <Typography fontSize={22} fontWeight={600} color={color.neutral.light}>
              Meios de Pagamento
            </Typography>
            <Stack alignItems="center" direction="row" mt={2} spacing={1}>
              <MoneyIcon height={25} color="white" />
              <VisaIcon />
              <MasterCard />
              {paymentOptionsRequest?.options
                .filter(({ name }) => paymentOptionToIcon[name.toLowerCase()])
                .map(({ name }) => paymentOptionToIcon[name.toLowerCase()])}
            </Stack>
          </Stack>
        </Box>
      </Stack>

      <LegalInformations>
        <Box m={3} mt={4}>
          <Stack alignItems="center" justifyContent={{ md: 'center' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
              <Stack direction="row" spacing={1}>
                <Link
                  style={{ textDecoration: 'none' }}
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`https://transparencyreport.google.com/safe-browsing/search?url=${window.location.origin}`}
                >
                  <SafeBrowsingLogo />
                </Link>
                <Typography color="white" fontSize={9}>
                  Google Safe Browsing
                  <br />
                  <strong>Site 100% Seguro</strong>
                </Typography>
              </Stack>
            </Stack>
            <Box mt={4} mb={4}>
              <Stack direction="row" spacing={2}>
                <Typography fontSize={14} color={color.neutral.light}>
                  Desenvolvido por
                </Typography>
                <a rel="noopener noreferrer" target="_blank" href="https://www.mypharma.com.br/">
                  <MypharmaLogo />
                </a>
              </Stack>
            </Box>
            <Typography fontSize={14} mb={2} color={color.neutral.light}>
              © Copyright {store?.settings.config_company_name} / {store?.name} -{' '}
              {store?.settings.config_cnpj} -{new Date().getFullYear()}
            </Typography>
          </Stack>
        </Box>
      </LegalInformations>
    </Container>
  )
}
