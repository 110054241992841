import { ClickAwayListener, Grow, Popper, Stack } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { DeliveryIcon, DropdownIcon } from '../../assets/icons'
import { Container, PopperContainer } from './styles'
import { useLocation } from 'react-router'
import AuthContext from '../../contexts/auth.context'
import { DeliveryRegions } from '../../services/delivery/response.interface'
import useSWR from 'swr'
import { getDeliveryRegions } from '../../services/delivery/delivery.service'
import { waypointContext } from '../../contexts/waypoint.context'
import { DeliveryRegionsDropdownContent } from '../DeliveryRegionsDropdownContent'
import { minutesToTime } from '../../helpers/dataConversion'

export const DeliveryRegionsDropdown: React.FC = () => {
  const { pathname } = useLocation()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [storeRegion, setStoreRegion] = useState<DeliveryRegions | undefined>()
  const { shouldRenderOptionals } = useContext(waypointContext)
  const { store } = useContext(AuthContext)

  const { data } = useSWR(
    shouldRenderOptionals || open ? 'deliveryRegions' : null,
    getDeliveryRegions
  )

  const handleClick = (event: any) => {
    setOpen((isOpen) => !isOpen)
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClickAway = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  useEffect(() => {
    const city = data?.regions.find(
      ({ _id }) => _id.toLowerCase() === store?.settings.config_store_city?.toLowerCase()
    )
    if (city) setStoreRegion(city)
  }, [data?.regions, store])

  return (
    <React.Fragment>
      <Container
        mainPage={pathname === '/produtos' || (window.innerWidth < 1200 && window.innerWidth > 600)}
        open={open}
        onClick={handleClick}
      >
        <Stack
          fontSize="inherit"
          letterSpacing="inherit"
          direction="row"
          gap={2}
          alignItems="center"
        >
          <DeliveryIcon />
          {storeRegion
            ? `Entrega
            ${minutesToTime(Math.round(storeRegion.averageTime)).value}
            ${minutesToTime(Math.round(storeRegion.averageTime)).suffix} em
            ${storeRegion._id} -
            ${storeRegion?.deliveryFees[0]?.neighborhood.city.state.code}`
            :
            'Tempos de entrega'
          }
        </Stack>
        <DropdownIcon className="arrowIcon" />
      </Container>
      <Popper transition style={{ zIndex: 2 }} open={open} anchorEl={anchorEl}>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Grow {...TransitionProps}>
              <PopperContainer>
                <DeliveryRegionsDropdownContent open={open} />
              </PopperContainer>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </React.Fragment>
  )
}
