import {
  TextField,
  FormControlLabel,
  Switch,
  Grid,
  InputAdornment,
  CircularProgress,
  Link,
  Box,
  Autocomplete,
} from '@mui/material'
import { Field, FieldProps, useFormikContext } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import useSWR from 'swr'
import TextFormField from '../../components/TextFormField'
import { normalizeStr } from '../../helpers/normalizeString'
import Address from '../../interfaces/address'
import Neighborhood from '../../interfaces/neighborhood'
import { getAddressByPostcode } from '../../services/address/address.service'
import { getDeliveryRegions } from '../../services/delivery/delivery.service'

export const AddressFormFields: React.FC = () => {
  const [isFetchingPostcode, setIsFetchingPostcode] = useState(false)
  const [availableNeighborhoods, setAvailableNeighborhoods] = useState<Neighborhood[] | undefined>()
  const { values } = useFormikContext()

  const onChangePostcode = useCallback(
    async (postcode: string, handleSuggestionAddress: (neighborhood: any) => any) => {
      setIsFetchingPostcode(true)
      const response = await getAddressByPostcode(postcode.replaceAll('-', ''))

      if (response.ok && response.data) {
        const { city, neighborhood, state, uf } = response.data.address
        const suggestionAddress = {
          name: neighborhood || '',
          city: {
            name: city || '',
            state: {
              name: state || '',
              code: uf,
            },
          },
        }
        handleSuggestionAddress(suggestionAddress)
      }

      setIsFetchingPostcode(false)
    },
    []
  )

  const { data } = useSWR('deliveryRegions', getDeliveryRegions)

  const findLocalDeliverys = useCallback(() => {
    if ((values as Address).neighborhood.city.name && data?.regions) {
      return data.regions.filter((region) =>
        region.deliveryFees.find((deliveryFee) =>
          normalizeStr(deliveryFee.neighborhood.city.name.toLowerCase()).includes(
            normalizeStr((values as Address).neighborhood.city.name).toLowerCase()
          )
        )
      )
    }
  }, [data?.regions, values])

  useEffect(() => {
    if ((values as Address).neighborhood.city.name) {
      const deliverys = findLocalDeliverys()

      if (deliverys) {
        const neighborhoods: Neighborhood[] = []
        deliverys.forEach((value) =>
          value.deliveryFees.forEach((deliveryFee) => {
            neighborhoods.push(deliveryFee.neighborhood)
          })
        )
        setAvailableNeighborhoods(neighborhoods)
      }
    }
  }, [values, findLocalDeliverys, setAvailableNeighborhoods])

  return (
    <Grid mb={2} container spacing={2}>
      <Grid item xs={12} md={3}>
        <Field name="postcode">
          {({ form, field,meta }: FieldProps) => (
            <TextFormField
              placeholder="000000000"
              label="CEP"
              fullWidth
              variant="outlined"
              InputProps={{
                endAdornment: isFetchingPostcode && (
                  <InputAdornment position="end">
                    <CircularProgress size={20} color="primary" />
                  </InputAdornment>
                ),
              }}
              field={field}
              form={form}
              meta={meta}
              value={field.value}
              onChange={(ev) => {
                form.setFieldValue('postcode', ev.target.value)
                if (ev.target.value.length > 7)
                  onChangePostcode(ev.target.value, (value) =>
                    form.setFieldValue('neighborhood', value)
                  )
              }}
              required
            />
          )}
        </Field>
        <Box mt={1}>
          <Link
            href="https://buscacepinter.correios.com.br/app/logradouro_bairro/index.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            Não sei meu cep
          </Link>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Field
          name="neighborhood.city.name"
          label="Cidade"
          fullWidth
          variant="outlined"
          required
          disabled
          as={TextField}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Field
          name="neighborhood.city.state.name"
          label="Estado"
          fullWidth
          disabled
          variant="outlined"
          as={TextField}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Field name="street" label="Rua" fullWidth variant="outlined" required component={TextFormField} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Field name="number" label="Número" fullWidth variant="outlined" component={TextFormField} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field name="complement" label="Complemento" fullWidth variant="outlined" component={TextFormField} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Field name="neighborhood.name">
          {({ field, form }: FieldProps) =>
            availableNeighborhoods && availableNeighborhoods.length > 1 ? (
              <Autocomplete
                options={availableNeighborhoods}
                onChange={(ev, value: any) => {
                  form.setFieldValue('neighborhood.name', value?.name)
                }}
                clearOnEscape={false}
                clearOnBlur={false}
                inputValue={field.value}
                filterOptions={(op) => op}
                getOptionLabel={(op: any) => op.name}
                renderInput={(props) => (
                  <TextField
                    label="Bairro"
                    required
                    variant="outlined"
                    {...props}
                    onChange={(e) => {
                      form.setFieldValue('neighborhood.name', e.target.value)
                    }}
                  />
                )}
              />
            ) : (
              <TextField label="Bairro" required fullWidth variant="outlined" {...field} />
            )
          }
        </Field>
      </Grid>

      <Grid item xs={12}>
        <Field name="isMain">
          {({ field, form }: FieldProps) => (
            <FormControlLabel
              control={
                <Switch
                  checked={field.value}
                  onChange={(ev, checked) => form.setFieldValue('isMain', checked)}
                />
              }
              label="Definir como endereço principal"
            />
          )}
        </Field>
      </Grid>
    </Grid>
  )
}
