export const getProductPrice = (price: number, promotional?: number, cupomDiscount?: number) => {
  if (promotional && cupomDiscount) {
    if (promotional > cupomDiscount) return cupomDiscount
    else return promotional
  }
  if (promotional && !cupomDiscount) return promotional
  if (!promotional && cupomDiscount) return cupomDiscount

  return price
}
