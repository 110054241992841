import React, { createContext, useCallback, useState } from 'react'
import { useCart } from '../hooks/useCart'
import { useEC } from '../hooks/useEC'
import Address from '../interfaces/address'
import { DeliveryMode } from '../interfaces/deliveryMode'
import Order from '../interfaces/order'
import { IPaymentCode } from '../interfaces/paymentCode'
import { IShipping } from '../interfaces/shipping'

interface CheckoutContextData {
  shipping: IShipping | null
  checkoutAddress: Address | null
  deliveryMode: DeliveryMode
  paymentCode: IPaymentCode
  setPaymentCode: React.Dispatch<React.SetStateAction<IPaymentCode>>
  setShipping: React.Dispatch<React.SetStateAction<IShipping | null>>
  setDeliveryMode: React.Dispatch<React.SetStateAction<DeliveryMode>>
  setCheckoutAddress: React.Dispatch<React.SetStateAction<Address | null>>
  onFinishOrder: (order: Order) => Promise<void>
}

const CheckoutContext = createContext({} as CheckoutContextData)
const { Provider } = CheckoutContext

export const CheckoutProvider: React.FC = ({ children }) => {
  const [shipping, setShipping] = useState<IShipping | null>(null)
  const [paymentCode, setPaymentCode] = useState<IPaymentCode>('pay_online')
  const [checkoutAddress, setCheckoutAddress] = useState<Address | null>(null)
  const [deliveryMode, setDeliveryMode] = useState<DeliveryMode>('own_delivery')

  const { clear, clearRemarketingCart } = useCart()
  const { purchase } = useEC()

  const onFinishOrder = useCallback(async (order: Order) => {
    await clear()
    purchase(order)
    setTimeout(async () => {
      await clearRemarketingCart()
    }, 500)
  }, [clear, purchase, clearRemarketingCart]
  )

  return (
    <Provider
      value={{
        shipping,
        paymentCode,
        deliveryMode,
        checkoutAddress,
        setShipping,
        onFinishOrder,
        setPaymentCode,
        setDeliveryMode,
        setCheckoutAddress,
      }}
    >
      {children}
    </Provider>
  )
}

export default CheckoutContext
