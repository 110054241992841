import { useCallback, useContext } from 'react'
import { StorageKeys } from '../config/keys'
import CartContext from '../contexts/cart.context'
import CheckoutContext from '../contexts/checkout.context'
import { getCupomDiscount as getDiscountFromCupom } from '../helpers/getCupomDiscount'
import { getProductActivePromotion } from '../helpers/getProductActivePromotion'
import Product from '../interfaces/product'
import { useDelivery } from './useDelivery'

export function useCart() {
  const { useFetchDelivery } = useDelivery()
  const { cart, setCart, setNotify, loadCart, clearRemarketingCart } = useContext(CartContext)
  const { checkoutAddress, deliveryMode, shipping } = useContext(CheckoutContext)
  const { data } = useFetchDelivery(true)

  const addProduct = (
    product: Product,
    quantity?: number,
    notifyText: string = 'Adicionado à cesta'
  ) => {
    if (!getProduct(product._id)) {

      const products = cart.products
      products.push({
        product,
        quantity: quantity || 1
      })
      setCart((prevCart) => ({ ...prevCart, products }))
      setNotify({ open: true, text: notifyText })
    } else {
      updateProduct(product, quantity || 1)
    }
  }

  const getProduct = (_id: string) => {
    return cart.products.find(_product => _product.product._id.includes(_id))
  }

  const removeProduct = (_id: Product['_id']) => {
    const products = cart.products.filter(_product => !_product.product._id.includes(_id))
    setCart((prevCart) => ({ ...prevCart, products }))

    if (!products.find(p => p.product._id.includes(_id))) {
      setNotify({ open: true, text: 'Produto removido da cesta' })
    }
  }

  const updateProduct = (product: Product, quantity: number) => {
    const products = cart.products
    const index = products.findIndex(_product => _product.product._id.includes(product._id))
    products[index].quantity = quantity !== undefined ? quantity : products[index].quantity + 1

    setCart((prevCart) => ({ ...prevCart, products }))
  }

  const getDeliveryRegion = useCallback(() => {
    return data.find(
      (_region) => _region.neighborhood._id === checkoutAddress?.neighborhood._id
    )
  }, [checkoutAddress, data])

  const getCupomDiscount = useCallback(
    (product: Product) => {
      if (cart.cupom!.length === 0) return undefined
      return getDiscountFromCupom(product, cart.cupom![0])
    },
    [cart.cupom]
  )

  const getDeliveries = useCallback(() => {
    if (checkoutAddress) {
      const { neighborhood } = checkoutAddress

      return data.find(_region => _region.neighborhood._id.includes(neighborhood._id))
    }
    return data
  }, [data, checkoutAddress])

  const getCartValue = useCallback(() => {
    const values = { subTotal: 0, discount: 0, deliveryFee: 0, total: 0, minimumPurchase: 0, freeFrom: 0 }

    cart.products!.forEach(({ product, quantity }) => {
      const promotional = getProductActivePromotion(product)?.price
      const cupomDiscount = getCupomDiscount(product)

      if (promotional && cupomDiscount) {
        if (promotional < cupomDiscount) {
          values.discount += product.price * quantity - quantity * promotional
          // values.subTotal += quantity * promotional
        } else {
          values.discount += product.price * quantity - quantity * cupomDiscount
          // values.subTotal += quantity * cupomDiscount
        }
      }
      if (promotional && !cupomDiscount) {
        values.discount += product.price * quantity - quantity * promotional
        // values.subTotal += quantity * promotional
      }
      if (!promotional && cupomDiscount) {
        values.discount += product.price * quantity - quantity * cupomDiscount
        // values.subTotal += quantity * cupomDiscount
      }

      values.subTotal += quantity * product.price
    })

    switch (deliveryMode) {
      case 'delivery_company':
        values.deliveryFee = shipping ? Number(shipping.price) : 0
        break

      case 'store_pickup':
        values.deliveryFee = 0
        break

      default:
        const delivery = getDeliveries()

        // check deliveries when user not logged or not have checkoutAddress
        if (delivery instanceof Array && delivery.length > 0) {
          const { feePrice } = delivery[0]
          const orderByMinimumPurchase = delivery.sort((a, b) => a.minimumPurchase - b.minimumPurchase)
          const { minimumPurchase } = orderByMinimumPurchase[0]

          const orderByFreeFrom = delivery.sort((a, b) => a.freeFrom - b.freeFrom)

          const { freeFrom } = orderByFreeFrom[0]

          values.freeFrom = freeFrom
          values.minimumPurchase = minimumPurchase > 0 && Number(values.subTotal - values.discount) >= minimumPurchase ? 0 : minimumPurchase
          values.deliveryFee = freeFrom > 0 && Number(values.subTotal - values.discount) >= freeFrom ? 0 : feePrice
        } else {
          if (!Array.isArray(delivery) && delivery) {
            const { feePrice, freeFrom, minimumPurchase } = delivery

            values.freeFrom = freeFrom
            values.minimumPurchase = minimumPurchase > 0 && Number(values.subTotal - values.discount) >= minimumPurchase ? 0 : minimumPurchase
            values.deliveryFee = freeFrom > 0 && Number(values.subTotal - values.discount) >= freeFrom ? 0 : feePrice
          }
        }
    }
    values.total = values.subTotal - values.discount + values.deliveryFee

    return values
  }, [cart.products, deliveryMode, shipping, getDeliveries, getCupomDiscount])

  const getProductsQuantity = () => {
    return cart.products.length
  }

  const clear = async () => {
    localStorage.removeItem(StorageKeys.cart)
    await loadCart()
  }

  return {
    clear,
    getProduct,
    addProduct,
    getCartValue,
    removeProduct,
    updateProduct,
    getCupomDiscount,
    getDeliveryRegion,
    getProductsQuantity,
    clearRemarketingCart
  }
}
