import { Link, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTheme } from 'styled-components'
import { DeliveryIcon } from '../../assets/icons'
import { floatToBRL } from '../../helpers/moneyFormat'
import { useCart } from '../../hooks/useCart'
import { CheckoutModal } from '../CheckoutModal'
import DeliveryFreeFromList from '../DeliveryFreeFromList'
import { CaptionCard } from './styles'

export const CardDeliveryFeeCaption: React.FC = () => {
  const { color } = useTheme()
  const { getCartValue } = useCart()
  const [open, setOpen] = useState(false)
  const { deliveryFee, subTotal, discount, freeFrom } = getCartValue()

  const openModal = () => setOpen(true)
  const onClose = () => setOpen(false)

  if (deliveryFee <= 0 || Number(subTotal - discount) >= freeFrom) {
    return <React.Fragment />
  }

  return (
    <React.Fragment>
      <CaptionCard>
        <Typography fontSize="inherit">
          Faltam {floatToBRL(freeFrom - (subTotal - discount))} para obter Frete Grátis.{' '}
          <br />
          Consulte as regras de frete grátis <Link onClick={openModal}>clicando aqui</Link>.
        </Typography>
      </CaptionCard>
      <CheckoutModal
        icon={<DeliveryIcon color={color.primary.medium} />}
        open={open}
        onClose={onClose}
      >
        <Typography mb={2} fontSize={16}>
          Regras de frete grátis
        </Typography>
        <DeliveryFreeFromList open={open} />
      </CheckoutModal>
    </React.Fragment>
  )
}

export default CardDeliveryFeeCaption
